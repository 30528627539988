import React from 'react'
/* import Grid from '@material-ui/core/Grid' */
import { Layout } from '../component/Layout'
import { graphql, PageProps, useStaticQuery } from 'gatsby'
import OffMarketMap from '../component/page_component/OffMarketMap'
import { Grid, useMediaQuery } from '@material-ui/core'
import theme from '../Theme/theme'
import { useIntl } from 'gatsby-plugin-intl'
import hreflangConfig from '../utils/hreflangConfig'
import { Helmet } from 'react-helmet'
import PropertyList from '../component/page_component/PropertyList'
import { Cinemagraph } from '../component/Cimenegraph'

const OffMarket: React.FC<PageProps> = (props: any) => {
  const intl = useIntl()
  const {
    location,
    pageContext: { data },
  } = props
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'))

  const hreflangLinks = hreflangConfig['/fr/off-market/']
  const dataCanonical = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `)
  const canonicalUrl = `${dataCanonical.site.siteMetadata.siteUrl}${location.pathname}`
  return (
    <Layout
      location={location}
      showFlotingMenu={false}
      maxWidth={false}
      title={intl.formatMessage({ id: 'PageTitleOffmarket' })}
    >
      <Helmet>
        <link rel="canonical" href={canonicalUrl} />
        {hreflangLinks &&
          Object.keys(hreflangLinks).map((lang) => (
            <link key={lang} rel="alternate" hrefLang={lang} href={hreflangLinks[lang]} />
          ))}
        <meta name="description" content="Off market - Kretz" />
      </Helmet>

      <Grid container justifyContent="center" id="offMarket">
        <PropertyList
          bgClr="#FFF"
          filterBackgroundColor={'#FFF'}
          data={data}
          location={location}
          title={intl.formatMessage({ id: 'new.Off market' })}
          description={''}
          image={'offmarketheader.jpg'}
          showCTA={false}
          titleOnly
          withFilter
        />
      </Grid>
      <Cinemagraph flixelId="jtsueywkv381kalwb9l5" flixelMobileId="7od2xokpjecfjrq2jgsh" />
    </Layout>
  )
}

export default OffMarket
